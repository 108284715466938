<template>
  <a-spin size="large" :spinning="loading">
    <div class="home" ref="home" @scroll="scrollHandler">
      <div class="left">
        <a-steps direction="vertical">
          <a-step
            status="finish"
            :class="[lang]"
            :title="'1.' + $t('ChooseYourCustomerSearchMethod')"
          >
            <div v-show="step == 1" slot="icon" class="circle active process">
              <svg-icon iconClass="w-search" class="licon"></svg-icon>
            </div>
            <div
              v-show="step == 2 || step == 3"
              slot="icon"
              class="circle active"
            >
              <svg-icon iconClass="w-search" class="licon"></svg-icon>
            </div>
          </a-step>
          <a-step
            :class="[lang]"
            :status="step >= 2 ? 'finish' : 'wait'"
            :title="'2.' + $t('InputBasicInformation')"
          >
            <div v-show="step == 1" slot="icon" class="circle">
              <svg-icon iconClass="gbase" class="licon"></svg-icon>
            </div>
            <div v-show="step == 2" slot="icon" class="circle active process">
              <svg-icon iconClass="wbase" class="licon"></svg-icon>
            </div>
            <div v-show="step == 3" slot="icon" class="circle active">
              <svg-icon iconClass="wbase" class="licon"></svg-icon>
            </div>
          </a-step>
          <a-step
            :class="[lang]"
            :status="step > 2 ? 'finish' : 'wait'"
            :title="'3.' + $t('CampaignsSettings')"
          >
            <div v-show="step == 1 || step == 2" slot="icon" class="circle">
              <svg-icon iconClass="gsetting" class="licon"></svg-icon>
            </div>
            <div v-show="step == 3" slot="icon" class="circle active process">
              <svg-icon iconClass="wsetting" class="licon"></svg-icon>
            </div>
          </a-step>
        </a-steps>
      </div>
      <div class="right">
        <Step1
          ref="step1"
          :detailObj="detailObj"
          :linkList="linkList"
          :loaded="loaded"
          v-show="step == 1"
          @callback="firstHandler"
        ></Step1>

        <div v-show="step == 2" class="box2">
          <a-form :form="form2" class="form" ref="form2Ref">
            <template v-if="selectedIndex == 2">
              <div class="label-des" :class="[lang]">
                <span>{{ $t("EnterLinkedInSearchURL") }}</span>
                <a-popover
                  :placement="lang == 'AR' ? 'bottomRight' : 'bottomLeft'"
                  style="z-index: 98"
                  trigger="click"
                  v-model="visiable"
                >
                  <template slot="content">
                    <div class="popbox beauty-scroll">
                      <p
                        class="main-tit"
                        :class="[lang]"
                        :style="{
                          'text-align': lang == 'AR' ? 'right' : 'left',
                        }"
                      >
                        {{ $t("findUrl") }}
                      </p>
                      <div
                        class="ptit"
                        :class="[lang]"
                        :style="{
                          'text-align': lang == 'AR' ? 'right' : 'left',
                        }"
                      >
                        <span
                          class="num"
                          :class="{ rtl: lang == 'AR' ? true : false }"
                          >1</span
                        ><span :class="[lang]">{{ $t("loginAccount") }}</span>
                      </div>
                      <div
                        class="ptit"
                        :class="[lang]"
                        :style="{
                          'text-align': lang == 'AR' ? 'right' : 'left',
                        }"
                      >
                        <span
                          class="num"
                          :class="{ rtl: lang == 'AR' ? true : false }"
                          >2</span
                        ><span>{{ $t("Enterkeywords") }}</span>
                      </div>
                      <div
                        :style="{
                          'text-align': lang == 'AR' ? 'right' : 'left',
                        }"
                      >
                        <img
                          class="search"
                          src="@/assets/img/search.png"
                          alt=""
                          style="display: inline"
                        />
                      </div>

                      <div
                        class="ptit ptit-3"
                        :class="[lang]"
                        :style="{
                          'text-align': lang == 'AR' ? 'right' : 'left',
                        }"
                      >
                        <span
                          class="num"
                          :class="{ rtl: lang == 'AR' ? true : false }"
                          >3</span
                        >
                        <div class="con-3">
                          <span>{{ $t("SelectThePeople") }}</span>
                          <div
                            class="txt-tip"
                            :style="{
                              'text-align': lang == 'AR' ? 'right' : 'left',
                            }"
                          >
                            {{ $t("SelectThePeopleRed") }}
                          </div>
                        </div>
                      </div>
                      <div
                        :style="{
                          'text-align': lang == 'AR' ? 'right' : 'left',
                        }"
                      >
                        <img
                          style="width: 420px; height: auto; display: inline"
                          src="@/assets/img/example.png"
                          alt=""
                        />
                      </div>

                      <div
                        class="ptit"
                        :class="[lang]"
                        :style="{
                          'text-align': lang == 'AR' ? 'right' : 'left',
                        }"
                      >
                        <span
                          class="num"
                          :class="{ rtl: lang == 'AR' ? true : false }"
                          >4</span
                        ><span>{{ $t("copyURL") }}</span>
                      </div>
                      <div
                        :style="{
                          'text-align': lang == 'AR' ? 'right' : 'left',
                        }"
                      >
                        <img
                          style="width: 532px; height: auto"
                          class="search"
                          src="@/assets/img/likurl.png"
                          alt=""
                        />
                      </div>

                      <!-- <div
                        class="link"
                        style="padding-top: 12px; padding-bottom: 20px"
                      >
                        {{
                          $t("ReferenceDocument")
                        }}:http://strategyBrain/handbook/test2
                      </div> -->
                    </div>
                  </template>

                  <a-icon
                    type="question-circle"
                    style="
                      font-size: 14px;
                      color: #0e756a;
                      cursor: pointer;
                      margin-left: 6px;
                    "
                  />
                </a-popover>
              </div>
              <a-form-item :class="[lang]">
                <a-input
                  size="large"
                  class="input"
                  v-decorator="[
                    'search_url',
                    {
                      initialValue: search_url,
                      rules: [
                        // { required: true, message: $t('selectUrl') },
                        {
                          required: true,
                          validator: async function (rule, value, callback) {
                            if (!value || !value.trim('')) {
                              callback($t('selectUrl'));
                              return;
                            }
                            // 添加逻辑校验是否存在调接口
                            let res = await isHaveSearchUrl(value);
                            if (!res.reslut) {
                              callback(res.msg);
                              return;
                            }
                            callback();
                          },
                        },
                      ],
                      trigger: 'blur',
                    },
                  ]"
                  :placeholder="$t('selectUrl')"
                />
              </a-form-item>
            </template>

            <div class="label-des" :class="[lang]">
              {{ $t("CompanyInformation") }}
            </div>
            <div
              class="wrap-select"
              :class="[lang]"
              v-if="knowlegeList[1] && knowlegeList[1].length > 0"
            >
              <a-select
                class="select"
                :placeholder="$t('selectHistory')"
                style="width: 400px; margin-bottom: 16px"
                size="large"
                @change="(value) => changeHandler(value, 'company')"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.content + '|||' + index"
                  v-for="(item, index) in knowlegeList[1]"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>
            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :maxLength="maxLength"
                :auto-size="{ minRows: 3, maxRows: 7 }"
                style="overflow-y: auto"
                :class="{
                  clicked: formData.company.isClick,
                  noClicked: !formData.company.isClick,
                }"
                @click="clickInput('company')"
                v-decorator="[
                  'company',
                  {
                    initialValue: formData.company.content,
                    trigger: 'blur',
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (
                            !value ||
                            !value.trim('') || !formData.company.isClick
                          ) {
                            callback($t('inputContent'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
                :placeholder="$t('CompanyInformationCont')"
              />
            </a-form-item>
            <!-- 职位名称 -->
            <div class="label-des" :class="[lang]">
              {{ $t("PositionName") }}
            </div>
            <div
              class="wrap-select"
              :class="[lang]"
              v-if="knowlegeList[9] && knowlegeList[9].length > 0"
            >
              <a-select
                class="select"
                :placeholder="$t('selectHistory')"
                style="width: 400px; margin-bottom: 16px"
                size="large"
                @change="(value) => changeHandler(value, 'positionName')"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.content + '|||' + index"
                  v-for="(item, index) in knowlegeList[9]"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>
            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :maxLength="maxLength"
                :auto-size="{ minRows: 2, maxRows: 7 }"
                style="overflow-y: auto"
                v-decorator="[
                  'positionName',
                  {
                    initialValue: formData.positionName.content,
                    trigger: 'blur',
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (!value || !value.trim('')) {
                            callback($t('PositionNamePlaceholder'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
                :placeholder="$t('PositionNamePlaceholder')"
              />
            </a-form-item>
            <!-- 汇报对象 -->
            <div class="label-des" :class="[lang]">
              {{ $t("ReportTo") }}
            </div>
            <div
              class="wrap-select"
              :class="[lang]"
              v-if="knowlegeList[10] && knowlegeList[10].length > 0"
            >
              <a-select
                class="select"
                :placeholder="$t('selectHistory')"
                style="width: 400px; margin-bottom: 16px"
                size="large"
                @change="(value) => changeHandler(value, 'reportTo')"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.content + '|||' + index"
                  v-for="(item, index) in knowlegeList[10]"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>
            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :maxLength="maxLength"
                :auto-size="{ minRows: 2, maxRows: 7 }"
                style="overflow-y: auto"
                v-decorator="[
                  'reportTo',
                  {
                    initialValue: formData.reportTo.content,
                    trigger: 'blur',
                     rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (!value || !value.trim('')) {
                            callback($t('ReportToPlaceholder'));
                            return;
                          }
                          callback();
                        },
                      },
                     ],
                  },
                ]"
                :placeholder="$t('ReportToPlaceholder')"
              />
            </a-form-item>
            <!-- 岗位职责 -->
            <div class="label-des" :class="[lang]">
              {{ $t("jobResponsibilities") }}
            </div>
            <div
              class="wrap-select"
              :class="[lang]"
              v-if="knowlegeList[11] && knowlegeList[11].length > 0"
            >
              <a-select
                class="select"
                :placeholder="$t('selectHistory')"
                style="width: 400px; margin-bottom: 16px"
                size="large"
                @change="(value) => changeHandler(value, 'jobResponsibilities')"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.content + '|||' + index"
                  v-for="(item, index) in knowlegeList[11]"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>
            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :maxLength="maxLength"
                :auto-size="{ minRows: 3, maxRows: 7 }"
                style="overflow-y: auto"
                v-decorator="[
                  'jobResponsibilities',
                  {
                    initialValue: formData.jobResponsibilities.content,
                    trigger: 'blur',
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (!value || !value.trim('')) {
                            callback($t('jobResponsibilitiesPlaceholder'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
                :placeholder="$t('jobResponsibilitiesPlaceholder')"
              />
            </a-form-item>
            <!-- 岗位要求 -->
            <div class="label-des" :class="[lang]">
              {{ $t("jobRequirements") }}
            </div>
            <div
              class="wrap-select"
              :class="[lang]"
              v-if="knowlegeList[12] && knowlegeList[12].length > 0"
            >
              <a-select
                class="select"
                :placeholder="$t('selectHistory')"
                style="width: 400px; margin-bottom: 16px"
                size="large"
                @change="(value) => changeHandler(value, 'jobRequirements')"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.content + '|||' + index"
                  v-for="(item, index) in knowlegeList[12]"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>

            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :auto-size="{ minRows: 4, maxRows: 7 }"
                style="overflow-y: auto"
                @click="clickInput('jobRequirements')"
                v-decorator="[
                  'jobRequirements',
                  {
                    initialValue: formData.jobRequirements.content,
                    trigger: 'blur',
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (!value || !value.trim('')) {
                            callback($t('jobRequirementsErr'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
                :placeholder="$t('jobRequirementsPlaceholder')"
              />
            </a-form-item>
            
            <!-- 工作地点 -->
            <div class="label-des" :class="[lang]">
              {{ $t("WorkLocation") }}
            </div>
            <div
              class="wrap-select"
              :class="[lang]"
              v-if="knowlegeList[13] && knowlegeList[13].length > 0"
            >
              <a-select
                class="select"
                :placeholder="$t('selectHistory')"
                style="width: 400px; margin-bottom: 16px"
                size="large"
                @change="(value) => changeHandler(value, 'WorkLocation')"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.content + '|||' + index"
                  v-for="(item, index) in knowlegeList[13]"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>

            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :auto-size="{ minRows: 2, maxRows: 7 }"
                style="overflow-y: auto"
                v-decorator="[
                  'WorkLocation',
                  {
                    initialValue: formData.WorkLocation.content,
                    trigger: 'blur',
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (!value || !value.trim('')) {
                            callback($t('WorkLocationPlaceholder'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
                :placeholder="$t('WorkLocationPlaceholder')"
              />
            </a-form-item>

            <!-- 工作方式 -->
            <div class="label-des" :class="[lang]">
              {{ $t("wayOfWorking") }}
            </div>
            <a-form-item :class="[lang]">
              <a-select
                class="select"
                :placeholder="$t('wayOfWorkingErr')"
                style="width: 400px;"
                size="large"
                v-decorator="[
                  'wayOfWorking',
                  {
                    initialValue: formData.wayOfWorking.content,
                    trigger: 'change',
                    rules: [
                      {
                        required: true,
                        message: $t('wayOfWorkingErr'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.label"
                  v-for="(item, index) in wayOfWorkingSelect"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.label }}
                  </div>
                </a-select-option>
              </a-select>
            </a-form-item>

            <!-- 薪资范围 -->
            <div class="label-des" :class="[lang]">
              {{ $t("salaryRange") }}
            </div>
            <a-form-item :class="[lang]" style="margin-bottom: 0;">
              <a-select
                class="select"
                :placeholder="$t('salaryRangeSelectPlaceholder')"
                style="width: 400px;"
                size="large"
                v-decorator="[
                  'salaryRangeCurrency',
                  {
                    initialValue: formData.salaryRangeCurrency,
                    trigger: 'change',
                    // 如果有值的话必须选一个类型
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (!value) {
                            // if ((form2.getFieldValue('salaryRangeMax') || form2.getFieldValue('salaryRangeMin')) && !value) {
                            callback($t('salaryRangeSelectPlaceholder'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.id"
                  v-for="(item) in currencyList"
                  :key="item.id"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </a-form-item>
            <div :class="[lang]" style="margin-top: 16px;">
              <a-form-item style="display: inline-block;" class="salary-range-item" :class="[lang]">
                <a-input-number
                  id="inputNumber"
                  :min="0"
                  :class="[lang]"
                  :placeholder="$t('minSalary')"
                  v-decorator="[
                    'salaryRangeMin',
                    {
                      initialValue: formData.salaryRangeMin,
                      trigger: 'blur',
                      rules: [
                        {
                          required: true,
                          validator: function (rule, value, callback) {
                            // 校验max必须大于min
                            if (
                              Number(value) > Number(form2.getFieldValue('salaryRangeMax')) ||
                              value == undefined || form2.getFieldValue('salaryRangeMax') == undefined
                            ) {
                              callback($t('salaryRangeErr'));
                              return;
                            }
                            callback();
                          },
                        },
                      ],
                    }
                  ]"
                />
              </a-form-item>
              <span style="margin: 10px;display: inline-block;" :class="[lang]">-</span>
              <a-form-item style="display: inline-block;" :class="[lang]">
                <a-input-number
                  id="inputNumber"
                  :min="0"
                  :class="[lang]"
                  :placeholder="$t('maxSalary')"
                  v-decorator="[
                    'salaryRangeMax',
                    {
                      initialValue: formData.salaryRangeMax,
                      trigger: 'blur',
                      rules: [
                        {
                          required: true,
                          validator: function (rule, value, callback) {
                            // 校验max必须大于min
                            callback();
                          },
                        },
                      ],
                    },
                  ]"
                  @blur="salaryRangeMinBlur"
                />
              </a-form-item>
            </div>

            <!-- 福利待遇 -->
            <div class="label-des" :class="[lang]">
              {{ $t("welfareTreatment") }}
            </div>
            <div
              class="wrap-select"
              :class="[lang]"
              v-if="knowlegeList[16] && knowlegeList[16].length > 0"
            >
              <a-select
                class="select"
                :placeholder="$t('selectHistory')"
                style="width: 400px; margin-bottom: 16px"
                size="large"
                @change="(value) => changeHandler(value, 'welfareTreatment')"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.content + '|||' + index"
                  v-for="(item, index) in knowlegeList[16]"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>

            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :auto-size="{ minRows: 3, maxRows: 7 }"
                style="overflow-y: auto"
                v-decorator="[
                  'welfareTreatment',
                  {
                    initialValue: formData.welfareTreatment.content,
                    trigger: 'blur',
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (!value || !value.trim('')) {
                            callback($t('welfareTreatmentPlaceholder'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
                :placeholder="$t('welfareTreatmentPlaceholder')"
              />
            </a-form-item>
            <div class="label-des" :class="[lang]">
              {{ $t("ContactInformation") }}
            </div>
            <div
              class="wrap-select"
              :class="[lang]"
              v-if="knowlegeList[7] && knowlegeList[7].length > 0"
            >
              <a-select
                class="select"
                :placeholder="$t('selectHistory')"
                style="width: 400px; margin-bottom: 16px"
                size="large"
                @change="(value) => changeHandler(value, 'concatInfo')"
              >
                <a-select-option
                  :class="[lang]"
                  :value="item.content + '|||' + index"
                  v-for="(item, index) in knowlegeList[7]"
                  :key="index"
                >
                  <div class="optionCont">
                    {{ item.show_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>
            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :auto-size="{ minRows: 3, maxRows: 7 }"
                style="overflow-y: auto"
                :class="{
                  clicked: formData.concatInfo.isClick,
                  noClicked: !formData.concatInfo.isClick,
                }"
                @click="clickInput('concatInfo')"
                v-decorator="[
                  'concatInfo',
                  {
                    initialValue: formData.concatInfo.content,
                    trigger: 'blur',
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          if (
                            !value ||
                            !value.trim('') ||
                            !formData.concatInfo.isClick
                          ) {
                            callback($t('inputContent'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
                :placeholder="$t('inputContent')"
              />
            </a-form-item>
            <template>
              <div v-for="(k, index) in formData.custList" :key="k.id">
                <div class="label-des del-wrap">
                  <div class="edit" v-show="!k.show">
                    <span :class="[lang]">{{
                      k.corpus_name ? k.corpus_name : $t("CustomContent")
                    }}</span>
                    <a-icon
                      @click="editHandler(k, index)"
                      type="edit"
                      style="color: #0e756a"
                    />
                  </div>
                  <a-form-item :key="k.id" :class="[lang]">
                    <a-input
                      v-show="k.show"
                      size="large"
                      class="input cusinput clicked"
                      type="input"
                      :index="index"
                      style="width: 361px"
                      @blur="saveInputCus"
                      :ref="'cus' + index"
                      :placeholder="$t('editTitle')"
                      v-decorator="[
                        `custList[${index}].corpus_name`,
                        {
                          initialValue: k.corpus_name,
                          trigger: 'blur',
                          rules: [
                            {
                              required: true,
                              validator: function (rule, value, callback) {
                                if (!value || !value.trim('')) {
                                  callback($t('editTitle'));
                                  return;
                                }
                                callback();
                              },
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-icon
                    type="delete"
                    class="delItem"
                    @click="delItem(index)"
                  />
                </div>
                <div
                  class="wrap-select"
                  :class="[lang]"
                  v-if="knowlegeList[6] && knowlegeList[6].length > 0"
                >
                  <a-select
                    class="select"
                    :placeholder="$t('selectHistory')"
                    style="width: 400px; margin-bottom: 16px"
                    size="large"
                    @change="(value) => changeHandlerCustomer(value, k, index)"
                  >
                    <a-select-option
                      :class="[lang]"
                      :value="item.content + '|||' + index"
                      v-for="(item, index) in knowlegeList[6]"
                      :key="index"
                    >
                      <div class="optionCont">
                        {{ item.show_name }}
                      </div>
                    </a-select-option>
                  </a-select>
                </div>
                <a-form-item :key="k.id" :class="[lang]">
                  <a-input
                    size="large"
                    class="input clicked"
                    type="textarea"
                    :auto-size="{ minRows: 3, maxRows: 7 }"
                    style="overflow-y: auto"
                    @blur="custListContent"
                    :custListContentIndex="index"
                    v-decorator="[
                      `custList[${index}].content`,
                      {
                        initialValue: k.content,
                        trigger: 'blur',
                        rules: [
                          {
                            required: true,
                            validator: function (rule, value, callback) {
                              if (!value || !value.trim('')) {
                                callback($t('inputCustomContent'));
                                return;
                              }
                              callback();
                            },
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('inputCustomContent')"
                  />
                </a-form-item>
                <!--  -->
              </div>
            </template>
            <div class="addItem" :class="[lang]" @click="addItem">
              {{ $t("addMore") }}
            </div>
            <div class="wrap-btn" style="margin-top: 40px; text-align: center">
              <a-button
                size="large"
                :class="[lang]"
                @click="backHandler"
                style="margin-right: 20px"
                >{{ $t("back") }}</a-button
              >
              <a-button
                size="large"
                type="primary"
                @click="nextStep"
                :class="[lang]"
                >{{ $t("NextStep") }}</a-button
              >
            </div>
          </a-form>
        </div>
        <!-- 第三步 -->
        <div v-show="step == 3" class="box3">
          <a-form :form="form3" class="form">
            <div class="mtit" :class="[lang]">{{ $t("BriefSummary") }}</div>
            <div class="stit" :class="[lang]">
              {{ $t("BriefSummaryIntro") }}
            </div>
            <a-form-item :class="[lang]">
              <a-input
                size="large"
                class="input"
                type="textarea"
                :auto-size="{ minRows: 15, maxRows: 50 }"
                style="overflow-y: auto"
                v-decorator="[
                  'short_description',
                  {
                    initialValue: short_description,
                    rules: [
                      {
                        required: true,
                        message: $t('shortDes'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('shortDes')"
              />
            </a-form-item>
            <!-- <div class="mtit">{{ $t("SetConversationLimits") }}</div>
            <div class="stit">
              {{ $t("SetConversationLimitsCont") }}
            </div>
            <a-form-item>
              <a-input
                size="large"
                class="input"
                type="textarea"
                :auto-size="{ minRows: 3, maxRows: 7 }"
                style="overflow-y: auto"
                v-decorator="[
                  'conversation_limit',
                  {
                    initialValue: formData.conversation_limit,
                    rules: [
                      {
                        required: true,
                        message: $t('dialogLimit'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('dialogLimit')"
              />
            </a-form-item> -->
            <div
              class="numbers"
              :style="{
                'justify-content': lang == 'AR' ? 'flex-end' : 'flex-start',
              }"
            >
              <span class="text" :class="[lang]">{{ $t("sendLimit") }}</span>
              <span
                @click="sub"
                class="sub"
                :class="{ green: num > 1 ? true : false }"
                ><a-icon type="minus" /></span
              ><span class="num">{{ numLg == 1 ? "0" + "" + num : num }}</span
              ><span class="add green" @click="add"
                ><a-icon type="plus"
              /></span>
              <a-popover
                :placement="lang == 'AR' ? 'bottomRight' : 'bottomLeft'"
                style="z-index: 98"
                trigger="click"
                v-model="step3Visiable"
              >
                <template slot="content">
                  <div class="popbox">
                    <p
                      class="main-tit"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      {{ $t("step3TipTitle") }}
                    </p>
                    <div
                      class="ptit"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span :class="[lang]">{{ $t("step3TipCon") }}</span
                      ><span :class="[lang]" class="txt-red">{{
                        $t("step3TipCon1")
                      }}</span>
                    </div>

                    <p
                      class="main-tit main-tit2"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                        'margin-top': '16px',
                      }"
                    >
                      {{ $t("step3TipTitle2") }}
                    </p>
                    <div
                      class="ptit"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span :class="[lang]">{{ $t("step3TipCon2") }}</span>
                    </div>
                    <div
                      class="btn-box"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        :class="[lang]"
                        class="btn-txt"
                        @click="goStep3Link"
                        >{{ $t("bindLinkAccTipBtn") }}</span
                      ><span :class="[lang]">{{
                        $t("bindLinkAccTipBtnTxt")
                      }}</span>
                    </div>
                  </div>
                </template>
                <a-icon
                  type="question-circle"
                  style="
                    font-size: 14px;
                    color: #0e756a;
                    cursor: pointer;
                    margin-left: 6px;
                  "
                />
              </a-popover>
            </div>
            <div class="switchbox" style="display: none">
              <span>
                <a-switch
                  class="switch"
                  size="small"
                  v-model="like_post"
                  @change="switchchange1"
                />{{ $t("dianzan") }}
              </span>
            </div>
            <div class="wrap-btn" style="margin-top: 40px; text-align: center">
              <a-button
                :class="[lang]"
                size="large"
                @click="backHandler"
                style="margin-right: 20px"
                >{{ $t("back") }}</a-button
              >
              <a-button
                :class="[lang]"
                size="large"
                type="primary"
                @click="nextStep"
                >{{ $t("Save") }}</a-button
              >
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Step1 from "../../sales/salesReps/component/step1";
export default {
  data() {
    let that = this;
    return {
      visiable: false,
      short_description: null,
      clength: 30,
      step: 1,
      selectedIndex: "2",
      like_post: false,
      switch2: false,
      loading: false,
      isEdit: false,
      //是否打开挽留弹窗
      confirmIsOpend: false,
      confirmLeave: false,
      maxLength: 100000000000,
      campaign_corpus: [],
      search_url: "",
      new_linkedin_id: null,
      canLogin: false,
      detailObj: {},
      canLeave: true,
      //是否请求完Linklist
      loaded: false,
      linkList: [],
      processStep: {
        step1: 0,
        step2: 2,
        step3: 0,
      },
      formData: {
        conversation_limit: that.$t("conversationRestrictions"),
        company: {
          isClick: false,
          content: that.$t("CompanyInformationCont"),
        },
        positionName: {
          isClick: true,
          content: ''
        },
        reportTo: {
          isClick: true,
          content: ''
        },
        jobResponsibilities: {
          isClick: true,
          content: ''
        },
        jobRequirements: {
          isClick: false,
          content: '',
        },
        WorkLocation: {
          isClick: true,
          content: ''
        },
        wayOfWorking: {
          isClick: true,
          content: undefined
        },
        welfareTreatment: {
          isClick: true,
          content: ''
        },
        // salaryRange: {
        //   currency_id: '',
        //   range: {
        //     min: undefined,
        //     max: undefined
        //   }
        // },
        salaryRangeCurrency: undefined,
        salaryRangeMin: undefined,
        salaryRangeMax: undefined,
        concatInfo: {
          isClick: false,
          content: that.$t("ContactInformationCont"),
        },
        custList: [],
      },
      num: 10,
      form2: this.$form.createForm(this, { name: "dynamic_rule" }),
      form3: this.$form.createForm(this, { name: "dynamic_rule" }),
      knowlegeList: {},
      step3Visiable: false,
      currencyList: []
    };
  },
  components: {
    Step1,
  },
  activated() {},
  created() {
    this.step = 1;
    //this.$refs["step1"].form.resetFields();
    this.custList = [];
    // this.form2.resetFields();
    // this.form3.resetFields();
    this.initData();
    this.getCurrencyList();
  },
  mounted() {
    let that = this;
    var doc = document.getElementsByClassName("admin-layout-main")[0];
    doc.onscroll = function () {
      that.visiable = false;
    };
    document.body.onscroll = function () {
      that.visiable = false;
    };
    document.getElementById("popContainer").onscroll = function () {
      that.visiable = false;
    };
  },
  watch: {
    lang(val) {
      console.log("lange", val);
      // this.getLocationList();
      // this.getTimeZone();

      // 点击输入框高亮Placeholder文案为输入框文案逻辑
      if (!this.formData.company.isClick) {
        this.form2.setFieldsValue({
          company: this.$t("CompanyInformationCont"),
        });
      }

      // if (!this.formData.jobRequirements.isClick) {
      //   this.form2.setFieldsValue({
      //     jobRequirements: this.$t("jobRequirementsPlaceholder"),
      //   });
      // }

      if (!this.formData.concatInfo.isClick) {
        this.form2.setFieldsValue({
          concatInfo: this.$t("ContactInformationCont"),
        });
      }
      // this.form2.setFieldsValue({
      //   conversation_limit: this.$t("conversationRestrictions"),
      // });

      // conversation_limit: that.$t("conversationRestrictions"),

      setTimeout(() => {}, 300);

      this.$nextTick(() => {
        if (this.step == 2) {
          this.form2.validateFields({
            force: true
          }, (err, values) => {
            console.log(err, values);
          });
        } else if (this.step == 3) {
          this.form3.validateFields({
            force: true
          }, (err, values) => {
            console.log(err, values);
          });
        }
      })
      // 切换语言重新获取货币数据
      this.getCurrencyList();
    },
    // email(val, old) {
    //   console.log(val, old);
    //   this.new_linkedin_id = null;
    // },
    step: {
      handler(val) {
        if (val === 2) {
          this.showStepPopover(2);
        }
        if (val === 3) {
          this.showStepPopover(3);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("setting", ["layout", "theme", "weekMode", "lang"]),

    numLg() {
      return this.num.toString().length;
    },
    wayOfWorkingSelect () {
      return [
        {
          label: this.$t("wayOfWorkingLabel"),
          value: 1,
        },
        {
          label: this.$t("wayOfWorkingLabel1"),
          value: 2,
        },
        {
          label: this.$t("wayOfWorkingLabel2"),
          value: 3,
        },
        {
          label: this.$t("wayOfWorkingLabel3"),
          value: 4,
        },
        {
          label: this.$t("wayOfWorkingLabel4"),
          value: 5,
        },
        {
          label: this.$t("wayOfWorkingLabel5"),
          value: 6,
        },
      ]
    }
    // email() {
    //   return this.$refs["step1"][0].form.getFieldValue("linkedin_email");
    // },
    // form1Val() {
    //   return this.$ref["step1"][0].form.getFieldsValue();
    // },
    // firstFinish() {
    //   if (
    //     this.selectedIndex !== "0" &&
    //     this.$route.query.linkedin_id !== "new" &&
    //     this.$route.query.status == "1"
    //   ) {
    //     return true;
    //   } else if (
    //     this.new_linkedin_id &&
    //     this.selectedIndex !== "0" &&
    //     this.canLogin
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  i18n: require("./capaingni18n"),
  beforeRouteLeave(to, from, next) {
    this.toPath = to;
    let { processStep, firstReg } = this.$refs["step1"];
    let status =
      processStep.step1 == 2 ||
      processStep.step2 == 2 ||
      processStep.step3 == 2;
    let that = this;
    if (firstReg && status) {
      this.$confirm({
        content: that.$t("TheAccountIsBeingBound"),
        okText: that.$t("Confirm"),
        cancelText: that.$t("Cancel"),
        onOk() {
          next();
        },
      });
    } else {
      next();
    }
  },
  methods: {
    initData() {
      let { edit, campaign_id } = this.$route.query;
      if (edit == "1") {
        // 编辑时获取详情数据
        this.loading = true;
        this.$axios(
          "/recruiter/campaign_info",
          {
            campaign_id: parseInt(campaign_id),
          },
          "post"
        )
          .then((res) => {
            this.loaded = true;
            this.loading = false;
            if (res.code == 200) {
              let {
                search_type,
                linkedin_id,
                like_post,
                campaign_name,
                short_description,
                add_limit,
                conversation_limit,
                campaign_corpus,
              } = res.data;
              this.detailObj = res.data;
              this.canLogin = true;
              this.num = add_limit;
              this.selectedIndex = search_type === 0 ? "1" : "2"; // 校验是否展示linkedURl 输入框
              this.new_linkedin_id = linkedin_id;
              this.like_post = like_post ? true : false;
              this.campName = campaign_name;
              this.short_description = short_description;
              this.formData.conversation_limit = conversation_limit;
              campaign_corpus.forEach((item) => {
                if (item.corpus_type == "1") {
                  this.formData.company = {
                    isClick: true,
                    content: item.content
                  };
                } else if (item.corpus_type == "9") {
                  this.formData.positionName = {
                    isClick: true,
                    content: item.content
                  };
                } else if (item.corpus_type == "10") {
                  this.formData.reportTo = {
                    isClick: true,
                    content: item.content
                  };
                } else if (item.corpus_type == "11") {
                  this.formData.jobResponsibilities = {
                    isClick: true,
                    content: item.content
                  };
                } else if (item.corpus_type == "12") {
                  this.formData.jobRequirements = {
                    isClick: true,
                    content: item.content
                  };
                } else if (item.corpus_type == "13") {
                  this.formData.WorkLocation = {
                    isClick: true,
                    content: item.content
                  };
                } else if (item.corpus_type == "14") {
                  // this.formData.wayOfWorking = {
                  //   isClick: true,
                  //   content: item.content
                  // };
                } else if (item.corpus_type == "15") {
                  // this.formData.salaryRangeCurrency = item.content.currency_id;
                  // this.formData.salaryRangeMin = item.content.range.min;
                  // this.formData.salaryRangeMax = item.content.range.max;
                } else if (item.corpus_type == "16") {
                  this.formData.welfareTreatment = {
                    isClick: true,
                    content: item.content
                  };
                } else if (item.corpus_type == "6") {
                  item.show = false;
                  this.formData.custList.push({
                    ...item,
                    id: Math.random()
                  });
                } else if (item.corpus_type == "7") {
                  this.formData.concatInfo = {
                    isClick: true,
                    content: item.content,
                  };
                }
              });
              this.getKnowlegeList(linkedin_id);
            }
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      } else {
        this.getLinkList();
      }
    },
    scrollHandler() {
      this.visiable = false;
    },
    firstHandler(data) {
      this.step++;
      let { campaign_name, linkedin_id } = data;
      this.campName = campaign_name;
      this.new_linkedin_id = linkedin_id;
    },
    delItem(index) {
      this.formData.custList.splice(index, 1);
    },

    ...mapMutations("setting", ["setDevice"]),
    editHandler(k, index) {
      k.show = true;
      setTimeout(() => {
        //this.$refs["cus" + index][0].focus;
        document.getElementsByClassName("cusinput")[index].focus();
      }, 500);
      //this.$refs["cus" + index][0].focus;
    },
    handleInput(event) {
      if (event.type == "click") {
        let index = event.currentTarget.getAttribute("index");
        //this.formData.custList[index].corpus_name = "";
        let obj = {};
        obj[`custList[${index}].corpus_name`] = "";

        this.form2.setFieldsValue(obj);
        return;
      }
    },
    saveInputCus(event) {
      console.log(event);
      let index = event.currentTarget.getAttribute("index");
      console.log(index, event.currentTarget.value);
      this.formData.custList[index].corpus_name = event.currentTarget.value;
      this.formData.custList[index].show = false;
    },
    custListContent (event) {
      let index = event.currentTarget.getAttribute("custListContentIndex");
      console.log(index, event.currentTarget.value);
      this.formData.custList[index].content = event.currentTarget.value;
    },
    clickInput(type) {
      this.formData[type].isClick = true;
    },
    backHandler() {
      this.step--;
    },
    switchchange1(val) {
      console.log(val);
    },
    switchchange2(val) {
      console.log(val);
    },
    getNumLength() {
      console.log(this.num.toString().length);
      this.num.toString().length;
    },
    add() {
      this.num++;
    },
    sub() {
      console.log(this.num);
      if (this.num > 1) {
        this.num--;
      }
    },
    addItem() {
      this.formData.custList.push({
        corpus_name: "",
        content: "",
        corpus_type: 6,
        show: false,
        id: Math.random()
      });
    },
    changeHandlerCustomer(value, obj, index) {
      obj.content = value.split("|||")[0];
      console.log('----obj', obj, value)
      const fields = this.form2.getFieldsValue()
      const { custList } = fields
      Object.assign(custList[index], obj)
      this.form2.setFieldsValue({
        custList
      })
      this.formData.custList[index].content = value.split("|||")[0]
    },
    changeHandler(value, type) {
      let obj = {};
      obj[type] = value.split("|||")[0];
      this.formData[type] = {
        content: value.split("|||")[0],
        isClick: true,
      };
      this.form2.setFieldsValue({
        [type]: value.split("|||")[0]
      })
    },
    nextStep() {
      if (this.step == 3) {
        if (!this.campName) {
          this.$warning({
            title: "",
            content: this.$t("Pleaseenterthecampaignname"),
          });
          return;
        }
        // 创建新任务
        this.form3.validateFields((err, values) => {
          if (!err) {
            this.loading = true;
            this.$axios(
              "/recruiter/create_campaign",
              {
                campaign_corpus: this.campaign_corpus,
                campaign_name: this.campName,
                linkedin_id: this.new_linkedin_id
                  ? parseInt(this.new_linkedin_id)
                  : null,
                short_description: values.short_description,
                conversation_limit: values.conversation_limit
                  ? values.conversation_limit
                  : "test",
                add_limit: this.num,
                like_post: this.like_post ? 1 : 0,
                search_type: this.selectedIndex == "1" ? 0 : 1,
                search_url: this.search_url,
              },
              "post"
            )
              .then((res) => {
                this.loading = false;
                if (res.code == 200) {
                  this.$router.push({
                    path: "/hr/recruiter/campaign",
                    query: { isHave: res.data.isHaveCampaign },
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                this.loading = false;
              });
          }
        });

        return;
      }
      if (this.step == 2) {
        this.form2.validateFields((err, values) => {
          if (!err) {
            console.log(err, values);
            let arr = [];
            arr[0] = {
              corpus_type: 1,
              content: values.company,
            };
            arr[1] = {
              corpus_type: 9,
              content: values.positionName,
            };
            arr[2] = {
              corpus_type: 10,
              content: values.reportTo,
            };
            arr[3] = {
              corpus_type: 11,
              content: values.jobResponsibilities,
            };
            arr[4] = {
              corpus_type: 12,
              content: values.jobRequirements,
            };
            arr[5] = {
              corpus_type: 13,
              content: values.WorkLocation,
            };
            arr[6] = {
              corpus_type: 14,
              content: values.wayOfWorking,
            };
            arr[7] = {
              corpus_type: 15,
              content: {
                currency_id: values.salaryRangeCurrency,
                range: {
                  min: Number(values.salaryRangeMin),
                  max: Number(values.salaryRangeMax)
                }
              },
            };
            arr[8] = {
              corpus_type: 16,
              content: values.welfareTreatment,
            };
            arr[9] = {
              corpus_type: 7,
              content: values.concatInfo,
            };
            if (values.custList) {
              values.custList.forEach((item) => {
                arr.push({
                  corpus_type: 6,
                  content: item.content,
                  corpus_name: item.corpus_name,
                });
              });
            }
            this.search_url = values.search_url;
            this.campaign_corpus = arr;
            this.loading = true;
            // 获取第三步的简短总结内容
            this.$axios(
              "/recruiter/get_short_description",
              { campaign_corpus: arr },
              "post"
            ).then((res) => {
              this.loading = false;
              if (res.code == 200) {
                // 赋值给第三步的简单总结
                this.form3.setFieldsValue({
                  short_description: res.data ? res.data.short_description : "",
                });
                this.step++;
              }
            });
          }
        });
        return;
      }
      this.step++;
    },
    edit() {
      this.$nextTick(() => {
        this.isEdit = !this.isEdit;
        console.log("-----");
        setTimeout(() => {
          this.$refs.campName.focus();
        }, 500);
      });
    },
    // 获取其他campaign的数据
    getKnowlegeList(linkedin_id) {
      this.$axios(
        "/public/knowledge_list",
        { campaign_type: 2, linkedin_id: parseInt(linkedin_id) },
        "post"
      ).then((res) => {
        if (res.code == 200) {
          this.knowlegeList = res.data;
        }
      });
    },
    // 获取link 账号信息 用于第一步展示
    getLinkList() {
      this.loading = true;
      this.$axios("/linkedin/list", { campaign_type: 2, status: 1 }, "post")
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            if (res.data.length > 0) {
              localStorage.removeItem("step1TemData");
            }
            this.linkList = res.data || [];
            this.loaded = true;
          }
          // if (res.code == 200) {
          //   if (res.data && res.data.length > 0) {
          //     this.linkList = res.data;
          //     this.firstReg = false;
          //   } else {
          //     this.firstReg = true;
          //     let temData = localStorage.getItem("step1TemData");
          //     if (temData) {
          //       this.isClickNext = true;
          //       let { step, processing, processStep, formData, tempLinkId } =
          //         JSON.parse(temData);
          //       this.step = step;
          //       this.processing = processing;
          //       this.processStep = processStep;
          //       this.formData = formData;
          //       this.tempLinkId = tempLinkId;
          //       this.campName = formData.campaign_name;
          //     }
          //   }
          // }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    showStepPopover(val) {
      const key = `ShowHrCampaignSetp${val}Popover`;
      // 添加校验是否是第一次打开，如果是第一次打开，则弹出
      const ShowCampaignSetpPopover = localStorage.getItem(key);
      if (ShowCampaignSetpPopover) {
        if (val === 2) {
          this.visiable = false;
        } else {
          this.step3Visiable = false;
        }
      } else {
        if (val === 2) {
          this.visiable = true;
        } else {
          this.step3Visiable = true;
        }
        localStorage.setItem(key, true);
      }
    },
    goStep3Link() {
      window.open(
        "https://leadgeneration.tawk.help/article/linkedin-usage-restrictions-and-settings"
      );
    },
    // 校验搜索URL是否存在
    async isHaveSearchUrl(val) {
      let resInfo = {};
      await this.$axios(
        "/recruiter/check_search_url",
        {
          search_url: val,
        },
        "post"
      ).then((res) => {
        resInfo = res && res.data;
      });
      return resInfo;
    },
    salaryRangeMinBlur() {
      this.$nextTick(() => {
        console.log('value---', this.form2.getFieldsValue(), this.formData)
        this.form2.validateFields(["salaryRangeMin"], (err, values) => {
          console.log('---', err, values)
          if (err) {
            //
          }
        });
      })
    },
    // 获取货币配置数据
    getCurrencyList () {
      this.$axios(
        '/public/currency_config',
        {},
        'post'
      ).then(res => {
        if (res.code === 200) {
          this.currencyList = res.data || []
        }
      })
    }
  },
};
</script>
<style lang="less">
.optionCont {
  width: 390px;
  padding-right: 10px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* 设置为行高的两倍 */
}
// .ant-modal-confirm-body {
//   .ant-modal-confirm-title {
//     display: none !important;
//   }
// }
</style>
<style lang="less" scoped>
.home {
  margin-top: 50px;
  padding: 20px 30px;
  display: flex;
  align-items: top;
  justify-content: space-between;
  font-family: Arial, Arial;
  .left {
    min-height: 450px;
    background: #fff;
    border-radius: 4px;
    padding: 44px;
    width: 379px;
    @media screen and (max-width: 1600px) {
      width: 300px;

      /deep/.ant-steps-vertical .ant-steps-item-title {
        font-size: 13px !important;
      }
    }
    .circle {
      width: 35px;
      height: 35px;
      box-sizing: border-box;
      background: #e1e1e1;
      border-radius: 50%;
      border: 4px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      .licon {
        font-size: 20px;
      }
    }
    .active {
      background: #0e756a;
    }
    .process {
      border-color: #d6f6f3;
      width: 40px;
      height: 40px;
      margin-left: -5px;
    }
  }
  .right {
    flex: 1;
    width: 100%;
    margin-left: 30px;

    .backBtn {
      background: #ffffff;
      margin-right: 14px;
      color: #80829f;
    }
    .title {
      // .campName {
      //   border: none;
      //   background: none;
      //   color: #000;
      //   height: 32px;
      // }
      // .campName:focus {
      //   border: 1px solid transparent !important;
      // }
      p {
        height: 32px;
        line-height: 32px;
        margin-bottom: 0;
      }
      display: flex;
      justify-content: space-between;
    }

    .box2 {
      background: #fff;
      padding: 38px 30px;
      .label-des {
        margin-bottom: 16px;
        font-family: Arial, Arial;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
      .del-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .delItem {
          color: #0e756a;
          cursor: pointer;
        }
      }
      .addItem {
        font-family: Arial, Arial;
        font-weight: 400;
        font-size: 14px;
        color: #0e756a;
        width: 440px;
        height: 48px;
        margin: 0 auto;
        text-align: center;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 2px solid #0e756a;
        line-height: 48px;
        cursor: pointer;
      }
      .clicked {
        color: #000;
      }
      .noClicked {
        color: #d6d6d6;
      }
      .sbtn {
        display: inline-block;
        margin-left: 18px;
        cursor: pointer;
      }
      .save {
        color: #0e756a;
      }
      .cancel {
        color: #f55353;
      }
    }
    .box3 {
      background: #fff;
      padding: 38px 30px;
      .mtit {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        margin-bottom: 6px;
      }
      .stit {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        margin-bottom: 16px;
      }
      .numbers {
        display: flex;
        align-items: center;
        user-select: none;
        .text {
          font-weight: 400;
          font-size: 14px;
          color: #000000;
        }
        .sub,
        .add {
          display: inline-block;
          width: 26px;
          height: 26px;
          text-align: center;
          line-height: 26px;
          background: #f0f0f0;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #dfdfdf;
          font-size: 12px;
          cursor: pointer;
        }
        .sub {
          margin-left: 20px;
        }
        .green {
          background: #fff;
          color: #0e756a;
          border-color: #dfdfdf;
        }
        .num {
          display: inline-block;
          height: 26px;
          line-height: 26px;
          margin: 0 19px;
          user-select: none;
        }
      }
      .switchbox {
        margin-top: 30px;
        height: 42px;
        background: #f2f3f8;
        border-radius: 4px 4px 4px 4px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        user-select: none;
        .switch {
          margin-right: 11px;
        }
      }
    }
    .salary-range-item {
      position: relative;
      /deep/.ant-form-explain {
        position: absolute;
        min-width: 440px;
      }
    }
  }
  /deep/.ant-input-number-handler-wrap {
    display: none;
  }
  /deep/.ant-steps-item-container {
    height: 100px;
  }
  /deep/.ant-input-number {
    width: 150px
  }
  .AR {
    /deep/.ant-input-number-input {
      text-align: right;
    }
  }
}
.popbox {
  width: 551px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  .main-tit {
    font-weight: bold;
    font-size: 16px;
    color: #0e756a;
    margin-bottom: 0;
  }
  .ptit {
    margin-top: 17px;
    font-size: 14px;
    color: #000000;
    user-select: none;
    .num {
      width: 19px;
      height: 19px;
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      line-height: 19px;
      text-align: center;
      margin-right: 7px;
      background: #0e756a;
      border-radius: 50%;
    }
    .rtl {
      margin-right: 0px;
      margin-left: 7px;
    }
    &-3 {
      display: flex;
      .num {
        min-width: 19px;
      }
      .con-3 {
        flex: 1;
        .txt-tip {
          color: #ff4d4f;
        }
      }
    }
  }
  .txt-red {
    color: #ff4d4f;
  }
  img {
    display: block;
    margin-top: 17px;
  }
  .search {
    // width: 375px;
    height: 47px;
  }
  .tips {
  }
  .btn-box {
    margin-top: 34px;
    color: #000;
    .btn-txt {
      color: #0e756a;
      cursor: pointer;
    }
  }
}
// /deep/.ant-select-selection__placeholder {
//   color: #0e756a;
// }
</style>
